/* src/styles.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

input[type="password"]::-ms-reveal {
  display: none;
}

/* Chat Container */
.chat-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 25rem;
  height: 100%;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  background: #FFFFFF;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
}

#chat-toggle:checked~.chat-container {
  transform: translateX(0);
}

/* Chat Window Layout */
.chat-window {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Header */
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #147e03;
  color: #FFFFFF;
  padding: 1rem;
  border-bottom: 1px solid #f0f0f0;
}

.chat-header h2 {
  font-size: 1.25rem;
  margin: 0;
}

/* Close Button */
.close-btn {
  background: #ed3300b3;
  color: #FFFFFF;
  padding: 0.5rem;
  border-radius: 8px;
  cursor: pointer;
}

/* Chat Body */
.chat-body {
  flex: 1;
  padding: 1rem;
  max-height: 100%;
  overflow-y: auto;
  background: #f9f9f9;
}

.message {
  margin-bottom: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  max-width: 80%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

.user-message {
  align-self: flex-end;
  background: #ffba08;
  color: #FFFFFF;
}

.bot-message {
  align-self: flex-start;
  background: #147e03;
  color: #FFFFFF;
}

/* Chat Footer */
.chat-footer {
  padding: 1rem;
  display: flex;
  gap: 0.5rem;
  border-top: 1px solid #f0f0f0;
}

.chat-footer input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
}

.chat-footer button {
  background: #ffba08;
  color: #147e03;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.chat-footer button:hover {
  background: #e0a007;
}